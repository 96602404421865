export default {
  productItemTypes: {
    kpl: "pcs",
    h: "hrs",
    kk: "mth",
    "hlö/kk": "pers./mth",
    "m³": "m³",
  },

  months: {
    tammikuu: "janyary",
    helmikuu: "february",
    maaliskuu: "march",
    huhtikuu: "april",
    toukokuu: "may",
    kesäkuu: "june",
    heinäkuu: "july",
    elokuu: "august",
    syyskuu: "september",
    lokakuu: "october",
    marraskuu: "november",
    joulukuu: "december",
  },
};
