<template>
  <div>
    <div v-if="loading" class="loader-container">
      <v-progress-circular
        class="justify-center"
        :size="70"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>

    <div v-if="!loading" class="d-flex flex-grow-1 app-container" v-resize="onResize">
      <!-- Navigation -->
      <v-navigation-drawer
        v-model="drawer"
        :key="drawerKey"
        app
        light
        floating
        class="elevation-1 navigation-wrapper"
        ref="menuDrawer"
      >
        <!-- Navigation menu info -->
        <template v-slot:prepend>
          <div
            style="height: 64px; display: flex; align-items: center; justify-content: center"
            v-if="customLogo"
            class="text-center mx-auto elevation-1"
          >
            <v-img height="60px" contain :src="customLogo"></v-img>
          </div>

          <div v-else class="pa-2">
            <div class="title font-weight-bold text-uppercase text-center primary--text">
              VUOKRANET
            </div>
          </div>
        </template>

        <!-- Navigation menus -->
        <main-menu :menu="linksByPermission" />
      </v-navigation-drawer>

      <!-- Header -->
      <v-app-bar app rounded v-resize="calcDrawerWidth">
        <v-card class="flex-grow-1 d-flex pa-0 ma-0" flat>
          <div class="d-flex flex-grow-1 align-center">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

            <v-spacer></v-spacer>

            <!-- Multiple accounts -->
            <v-menu offset-y left transition="slide-y-transition" :close-on-content-click="false">
              <template v-slot:activator="{ on, value }">
                <v-btn text v-on="on">
                  <span class="primary--text menu-item-name">
                    {{
                      isGroupMode()
                        ? "Ryhmänäkymä"
                        : truncateString(currentUser.currentAccount.name, getTruncateNumber)
                    }}
                  </span>
                  <v-icon v-if="value" right>mdi-chevron-up</v-icon>
                  <v-icon v-if="!value" right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-list>
                  <v-list-item>
                    <v-autocomplete
                      :items="usersAccounts"
                      item-value="_id"
                      item-text="name"
                      solo
                      no-filter
                      @input.native="getUserAccounts"
                      @change="switchToAnotherAccount"
                      hint="Nimi, email tai *"
                      persistent-hint
                      return-object
                    >
                    </v-autocomplete>
                  </v-list-item>

                  <v-list-item>
                    <v-btn small color="primary" @click="setGroupMode">{{
                      isGroupMode() ? "Asiakasnäkymään" : "Ryhmänäkymään"
                    }}</v-btn>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>

            <span class="mr-1 ml-1 menu-item-name">
              {{ this.currentUser.name.split(" ")[0] }}
            </span>

            <!-- User -->
            <toolbar-user />
          </div>
        </v-card>
      </v-app-bar>

      <!-- Main view -->
      <v-main>
        <v-container class="fill-height" fluid>
          <v-layout>
            <slot></slot>
          </v-layout>
        </v-container>
      </v-main>

      <!--  show pdf viewer -->
      <pdf-viewer :value="showPdfViewer"></pdf-viewer>

      <!-- Footer -->
      <custom-footer
        v-if="showFooter"
        :style="{ 'margin-left': drawerWidth + 'px' }"
      ></custom-footer>
    </div>
  </div>
</template>

<script>
import axiosMethods from "../mixins/axios";
import mixins from "../mixins/mixins";
import { mapState, mapActions, mapGetters } from "vuex";
import { debounce, setCustomStyles, loadFont } from "../utils/helpers";
import PdfViewer from "@/components/PdfViewer.vue";

// Navigation menu configurations
import config from "@/configs";
import MainMenu from "@/components/Navigation/MainMenu";
import ToolbarUser from "@/components/Navigation/ToolbarUser";
import CustomFooter from "@/components/Navigation/Footer";

export default {
  mixins: [mixins],

  components: {
    MainMenu,
    ToolbarUser,
    CustomFooter,
    PdfViewer,
  },

  data: () => ({
    accountInput: "",
    drawer: null,
    navigation: config.navigation,
    windowSize: 0,
    drawerWidth: 0,
    drawerKey: 0,
  }),

  async created() {
    // Get user
    try {
      this.$store.commit("setLoading", true);
      await this.$nextTick();
      await this.getUser();
      this.$store.commit("setLoading", false);
    } catch (err) {
      this.showPopup(err, "error");
    }
  },

  mounted() {
    this.$nextTick(() => {
      // Load custom styles
      if (this.loadCustomStyles) {
        setCustomStyles(this.currentUser.currentAccount.group, this.$vuetify);
      } else {
        // Load just font
        const font = "Bai Jamjuree";
        loadFont(font);
      }
    });
  },

  computed: {
    ...mapState("account", ["currentUser", "accounts", "usersAccounts"]),
    ...mapGetters("account", ["invoiceService", "getCustomLogo"]),
    ...mapState("pdfViewer", ["showPdfViewer"]),

    loading() {
      return this.$store.state.loading;
    },

    loadCustomStyles() {
      if (
        !this.currentUser.bypassCustomStyles &&
        this.currentUser?.currentAccount?.group &&
        !this.currentUser.currentAccount.group.bypassGroupStyles
      )
        return true;
      else return false;
    },

    showFooter() {
      if (this.loadCustomStyles) {
        return this.currentUser.currentAccount.group.showFooter;
      } else {
        return false;
      }
    },

    customLogo() {
      if (this.loadCustomStyles && this.getCustomLogo) {
        const base64String = this.getCustomLogo.buffer;
        const mimeType = this.getCustomLogo.mimeType;
        return `data:${mimeType};base64,${base64String}`;
      } else {
        return null;
      }
    },

    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },

    linksByPermission() {
      const routeAccess = this.$store.state.userProfile.routePermissions;
      const itemType = this.isGroupMode() ? "groupItems" : "items";
      const invoiceService = this.invoiceService;

      if (!routeAccess) {
        return;
      }

      let mainMenu = [];

      // Mainmenu
      this.navigation.menu.forEach((menu) => {
        let links = [];

        // Main menu links
        menu[itemType].forEach((item) => {
          // Check if main level is restricted
          if (routeAccess[item.show]) {
            // check subItems
            if (item.items) {
              let subItems = [];

              item.items.forEach((subItem) => {
                // check if subItem is restricted
                if (
                  routeAccess[subItem.show] &&
                  (!subItem.doNotShowOnService ||
                    !subItem.doNotShowOnService.includes(invoiceService))
                )
                  subItems.push(subItem);
              });

              // set subItems to item items
              item.items = subItems;
            }

            // Push item to links
            links.push(item);
          }
        });

        if (links.length > 0) {
          mainMenu.push({ text: menu.text, items: links });
        }
      });

      return mainMenu;
    },

    getTruncateNumber() {
      return this.windowSize > 500 ? 25 : 15;
    },
  },

  watch: {
    drawer(newVal, oldVal) {
      if (oldVal == null) this.calcDrawerWidth();
    },

    accountInput: debounce(function (newVal) {
      this.searchUsersAccounts(`/api/v1/account/search-users-accounts?search=${newVal}`);
    }, 1000),
  },

  methods: {
    ...mapActions("account", ["switchAccount", "getCurrentUser", "searchUsersAccounts"]),

    calcDrawerWidth() {
      this.drawerWidth = !this.drawer
        ? 0
        : this.$refs.menuDrawer
        ? this.$refs.menuDrawer.$el.offsetWidth || 0
        : 0;
    },

    onResize() {
      this.windowSize = window.innerWidth;
    },

    getUserAccounts(event) {
      this.accountInput = event.target.value;
    },

    showSnackbar(value) {
      this.color = value.color;
      this.text = value.text;
      this.snackbar = true;
    },

    async getUser() {
      if (this.isLoggedIn) {
        try {
          await this.getCurrentUser();
        } catch (err) {
          throw new Error(err);
        }
      }
    },

    async switchToAnotherAccount(account) {
      if (!account._id) return;

      try {
        if (
          !this.isGroupMode() &&
          String(this.currentUser.currentAccount._id) == String(account._id)
        ) {
          return this.showPopup("Olet jo kyseisellä tilillä.", "error");
        }

        this.$store.commit("setLoading", true);
        await this.$nextTick();
        await this.switchAccount(account._id);
        this.$router.push("/overview").catch(() => {});
        this.showPopup(`Tiliksi vaihdettiin ${account.name}`, "success");
        this.$store.commit("setLoading", false);
      } catch (err) {
        this.showPopup(err, "error");
        this.$store.commit("setLoading", false);
      }
    },

    async setGroupMode() {
      try {
        const res = await axiosMethods.get(`/api/v1/user/set-group-mode/`);
        this.$store.commit("setLoading", true);
        await this.$nextTick();
        this.$store.commit("setUserData", res.data.userData);
        this.$store.commit("deleteContract");
        await this.getUser();

        if (this.isGroupMode()) {
          this.$router.push("/group/overview");
          this.showPopup("Näkymä vaihdettiin ryhmäksi", "success");
        } else {
          this.$router.push("/overview");
          this.showPopup("Näkymä vaihdettiin asiakastilille", "success");
        }
        this.$store.commit("setLoading", false);
      } catch (err) {
        this.showPopup(err, "error");
        this.$store.commit("setLoading", false);
      }
    },
  },
};
</script>

<style scoped>
.navigation-wrapper {
  overflow-y: auto;
}

.loader-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 500px) {
  .menu-item-name {
    font-size: 11px !important;
  }
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>
