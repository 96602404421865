export default {
  productItemTypes: {
    kpl: "",
    h: "tim",
    kk: "mån",
    "hlö/kk": "pers./mån",
    "m³": "m³",
  },

  months: {
    tammikuu: "januari",
    helmikuu: "februari",
    maaliskuu: "mars",
    huhtikuu: "april",
    toukokuu: "maj",
    kesäkuu: "juni",
    heinäkuu: "juli",
    elokuu: "augusti",
    syyskuu: "september",
    lokakuu: "oktober",
    marraskuu: "november",
    joulukuu: "december",
  },
};
