<template>
  <v-dialog v-model="showPdfViewer" fullscreen :persistent="showPdfViewer">
    <v-card style="background-color: #f2f2f2">
      <v-toolbar
        color="surface"
        ref="toolbar"
        style="z-index: 9999999; position: fixed; top: 0; width: 100%"
        height="60px"
      >
        <v-checkbox
          v-if="!loading"
          v-model="paginate"
          label="Sivuta"
          class="mr-sm-2 mr-1"
          hide-details
          dense
        ></v-checkbox>

        <!-- Pages -->
        <div v-if="paginate && !loading" class="mr-2">
          <v-icon color="primary" :disabled="page <= 1" @click="page--">mdi-chevron-left</v-icon>
          {{ page }} / {{ pageCount }}
          <v-icon color="primary" :disabled="page >= pageCount" @click="page++"
            >mdi-chevron-right</v-icon
          >
        </div>

        <!-- Zoom -->
        <div v-if="!loading">
          <v-icon class="mr-1" color="primary" @click="width += 200"
            >mdi-magnify-plus-outline</v-icon
          >

          <v-icon color="primary" @click="width > 200 ? (width -= 200) : (width = width)"
            >mdi-magnify-minus-outline</v-icon
          >
        </div>

        <v-spacer></v-spacer>

        <v-icon v-if="!loading" @click="download" color="primary" class="mr-sm-2 mr-1">
          mdi-download</v-icon
        >

        <v-icon @click="revokePdfUrl()">mdi-close</v-icon>
      </v-toolbar>

      <!-- Loader -->
      <full-page-loader
        v-if="loading"
        text="Ladataan pdf-tiedostoa..."
        class="full-page-loader"
      ></full-page-loader>

      <pdf-embed
        ref="pdfRef"
        :source="pdfSource"
        :width="width"
        :page="page"
        @rendered="handleDocumentRender"
        style="overflow: auto; margin-top: 60px"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import PdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import mixins from "@/mixins/mixins";
import FullPageLoader from "./FullPageLoader.vue";
import { mapState, mapMutations } from "vuex";

export default {
  props: {
    value: { type: Boolean, default: false },
  },

  mixins: [mixins],

  components: {
    PdfEmbed,
    FullPageLoader,
  },

  data() {
    return {
      loading: true,
      page: null,
      pageCount: 1,
      paginate: false,
    };
  },

  computed: {
    ...mapState("pdfViewer", ["pdfSource", "pdfName"]),

    showPdfViewer: {
      get() {
        return this.$store.state.pdfViewer.showPdfViewer;
      },
      set(value) {
        this.setShow(value);
      },
    },

    width: {
      get() {
        return this.$store.state.pdfViewer.width;
      },
      set(val) {
        this.setWidth(val);
      },
    },
  },

  watch: {
    paginate() {
      this.page = this.paginate ? 1 : null;
    },
  },

  methods: {
    ...mapMutations("pdfViewer", ["setWidth", "revokePdfUrl"]),

    handleDocumentRender() {
      this.pageCount = this.$refs.pdfRef.pageCount;
      this.loading = false;
    },

    async download() {
      try {
        const name = this.pdfName || "vuokranet.pdf";
        await this.$refs.pdfRef.download(name);
      } catch (err) {
        this.showPopup(err);
      }
    },
  },
};
</script>

<style scoped>
.vue-pdf-embed >>> div {
  margin-bottom: 5px;
}
</style>
