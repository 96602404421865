<template>
  <v-container>
    <v-form ref="form">
      <v-row dense justify="center">
        <!-- TIME -->
        <v-col cols="12" md="6" lg="4" class="pr-2">
          <h2 class="mb-3">Vuokra-aika</h2>

          <v-col cols="12">
            <label>Sopimustyyppi</label>
            <v-radio-group
              v-model="leaseType"
              :mandatory="true"
              @change="leaseType == 'Toistaiseksi voimassa oleva' ? (endDate = '') : endDate"
              style="padding-top: 0; margin-top: 10px"
              hide-details
            >
              <v-radio
                label="Toistaiseksi voimassa oleva"
                value="Toistaiseksi voimassa oleva"
              ></v-radio>
              <v-radio label="Määräaikainen" value="Määräaikainen"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12">
            <label>Vuokrasopimuksen alkamispäivä</label>
            <v-menu
              ref="startMenu"
              v-model="startMenu"
              :close-on-content-click="false"
              min-width="290"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormattedStart"
                  v-bind="attrs"
                  v-on="on"
                  :rules="validations.required"
                  outlined
                  dense
                  append-icon="mdi-calendar"
                  v-prevent-manual-input
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                first-day-of-week="1"
                @input="startMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col v-if="leaseType == 'Määräaikainen'" cols="12">
            <label>Vuokrasopimuksen päättymispäivä</label>
            <v-menu ref="endMenu" v-model="endMenu" :close-on-content-click="false" min-width="290">
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormattedEnd"
                  v-bind="attrs"
                  v-on="on"
                  :rules="validations.required"
                  outlined
                  dense
                  append-icon="mdi-calendar"
                  v-prevent-manual-input
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                first-day-of-week="1"
                @input="endMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12">
            <label>Hallintaoikeus / Sisäänmuuttopäivä</label>
            <v-menu
              ref="moveInMenu"
              v-model="moveInMenu"
              :close-on-content-click="false"
              min-width="290"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormattedMoveIn"
                  v-bind="attrs"
                  v-on="on"
                  :rules="validations.required"
                  outlined
                  dense
                  append-icon="mdi-calendar"
                  v-prevent-manual-input
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="moveInDate"
                first-day-of-week="1"
                @input="moveInMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12">
            <label>Vuokralaisen ensimmäinen mahdollinen irtisanomispäivä</label>
            <v-radio-group
              v-model="showDismissalDate"
              :mandatory="false"
              style="padding-top: 0; margin-top: 10px"
              hide-details
            >
              <v-radio label="Piilota" :value="false"></v-radio>
              <v-radio label="Näytä" :value="true"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col v-if="showDismissalDate" cols="12">
            <label>Ensimmäinen irtisanomispäivä</label>
            <v-menu
              ref="dismissalMenu"
              v-model="dismissalMenu"
              :close-on-content-click="false"
              min-width="290"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormattedDismissal"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                  append-icon="mdi-calendar"
                  v-prevent-manual-input
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dismissalDate"
                first-day-of-week="1"
                @input="dismissalMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12">
            <label>Poismuuttopäivän tyyppi</label>
            <v-radio-group
              v-model="moveOut"
              :mandatory="true"
              style="padding-top: 0; margin-top: 10px"
            >
              <v-radio label="AHVL mukainen" value="AHVL"></v-radio>
              <v-radio label="LHVL mukainen" value="LHVL"></v-radio>
              <v-radio label="Sopimuksen päättymispäivä" value="endDate"></v-radio>
            </v-radio-group>
          </v-col>
        </v-col>

        <!-- RENT -->
        <v-col cols="12" md="6" lg="4">
          <h2 class="mb-3">Vuokra</h2>

          <v-col cols="12">
            <label>Vuokranmaksun alkamispäivä</label>
            <v-menu
              ref="firstRentMenu"
              v-model="firstRentMenu"
              :close-on-content-click="false"
              min-width="290"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormattedRentFirst"
                  v-bind="attrs"
                  v-on="on"
                  :rules="validations.required"
                  outlined
                  dense
                  append-icon="mdi-calendar"
                  v-prevent-manual-input
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="firstRentDate"
                first-day-of-week="1"
                @input="firstRentMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12">
            <label>Eräpäivä (sanallinen)</label>
            <v-text-field
              v-model="rentDueDate"
              placeholder="Sanallinen kuvaus..."
              :rules="validations.required"
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <label>Vuokra € / kk</label>
            <v-text-field
              v-model.number="rent"
              type="number"
              :rules="validations.required"
              step="0.01"
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <label>Valitse vuokraa vastaava tuote</label>
            <v-autocomplete
              v-model="rentProduct"
              :items="products"
              item-text="desc"
              item-value="_id"
              dense
              outlined
              return-object
              clearable
              hide-details
            ></v-autocomplete>
          </v-col>

          <v-col cols="12">
            <label>Oletuseräpäivä</label>
            <v-select
              v-model="rentDueDateNumber"
              outlined
              dense
              :items="globalValues.dueDates"
              item-text="text"
              item-value="date"
              hide-details
            ></v-select>
          </v-col>

          <v-col cols="12">
            <label style="margin-bottom: 0">Vesimaksu</label>

            <v-radio-group
              v-model="waterChargeType"
              :mandatory="true"
              style="padding-top: 0; margin-top: 10px"
              hide-details
            >
              <v-radio label="Vesimaksuennakko, tasataan kulutuksen mukaan" value="usage"></v-radio>
              <v-radio label="Kiinteä summa / kk" value="fixed"></v-radio>
              <v-radio
                label="Vesimaksu sisältyy vuokraan"
                value="included"
                @click="waterChargePerMonth = null"
              ></v-radio>
              <v-radio
                label="Ei vesimaksua"
                value="noCharge"
                @click="
                  waterChargePerMonth = null;
                  waterProduct = null;
                "
              ></v-radio>
            </v-radio-group>
          </v-col>

          <div v-if="waterChargeType == 'usage' || waterChargeType == `fixed`">
            <v-col cols="12">
              <label style="margin-bottom: 0">Vesimaksu €/hlö/kk</label>
              <v-text-field
                v-model.number="waterChargePerMonth"
                type="number"
                step="0.01"
                outlined
                dense
                hide-details
              />
            </v-col>

            <v-col cols="12">
              <label style="margin-bottom: 0">Vesimaksujen määrä</label>
              <v-text-field
                v-model="waterChargeCount"
                outlined
                dense
                v-prevent-paste
                v-only-numbers
                hide-details
                suffix="kpl"
              />
            </v-col>

            <v-col cols="12">
              <label>Valitse vesimaksua vastaava tuote</label>
              <v-autocomplete
                v-model="waterProduct"
                :items="products"
                item-text="desc"
                item-value="_id"
                dense
                outlined
                return-object
                clearable
                hide-details
              ></v-autocomplete>
            </v-col>
          </div>

          <v-col cols="12">
            <label style="margin-bottom: 0">Sähkömaksu</label>
            <v-radio-group
              v-model="electricity"
              :mandatory="true"
              style="padding-top: 0; margin-top: 10px"
              hide-details
            >
              <v-radio label="Vuokralainen tekee oman sähkösopimuksen" value="own"></v-radio>
              <v-radio label="Kiinteä summa / kk" value="fixed"></v-radio>
              <v-radio
                label="Sähkömaksu sisältyy vuokraan"
                value="included"
                @click="electricityAmount = null"
              ></v-radio>
              <v-radio
                label="Ei sähkömaksua"
                value="noCharge"
                @click="electricityAmount = null"
              ></v-radio>
            </v-radio-group>
          </v-col>

          <v-col v-if="electricity == 'fixed'" cols="12">
            <label>Sähkömaksu €/kk</label>
            <v-text-field
              v-model.number="electricityAmount"
              type="number"
              step="0.01"
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <label>Saunamaksu €</label>
            <v-text-field
              v-model.number="saunaCost"
              type="number"
              step="0.01"
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <label>Autopaikka €/kk</label>
            <v-text-field
              v-model.number="parkingCost"
              type="number"
              step="0.01"
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <label>Pesutupa €</label>
            <v-text-field
              v-model.number="laundretteCost"
              type="number"
              step="0.01"
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <label>Muut kustannukset</label>
            <v-text-field v-model="otherCostsDetails" outlined dense hide-details />
          </v-col>

          <v-col cols="12">
            <label>€/kk</label>
            <v-text-field
              v-model.number="otherCosts"
              type="number"
              step="0.01"
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col class="mt-1" cols="12">
            <h3>Muut tuotteet</h3>
          </v-col>

          <div v-for="(product, idx) in otherProducts" :key="'a' + idx">
            <v-col
              cols="12"
              class="py-0"
              style="cursor: pointer"
              @click="otherProducts.splice(idx, 1)"
            >
              <small class="error--text">Poista</small>
            </v-col>

            <v-col cols="12">
              <v-autocomplete
                v-model="product.productId"
                :items="products"
                label="Valitse tuote"
                item-text="desc"
                item-value="_id"
                dense
                outlined
                return-object
                :rules="validations.required"
                hide-details
              ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model.number="product.amount"
                label="Hinta"
                type="number"
                step="0.01"
                outlined
                dense
                hide-details
                suffix="€"
                @change="updateOtherProducts"
                :rules="validations.required.concat(validations.positiveNumber)"
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model.number="product.count"
                label="Määrä"
                outlined
                dense
                v-prevent-paste
                v-only-numbers
                hide-details
                suffix="kpl"
                @change="updateOtherProducts"
                :rules="validations.required.concat(validations.positiveNumber)"
              />
            </v-col>
          </div>

          <v-col class="mt-1" cols="12">
            <v-btn small @click="addOtherProduct" color="primary">Lisää muu tuote</v-btn>
          </v-col>
        </v-col>
      </v-row>

      <v-divider class="mt-5 mb-5"></v-divider>

      <!-- DEPOSIT -->
      <v-row dense justify="center">
        <v-col cols="12" md="4" class="pr-2">
          <h2>Vakuus</h2>

          <v-checkbox
            v-model="useDeposit"
            label="Vakuus peritään vuokralaiselta"
            hide-details
          ></v-checkbox>

          <v-col cols="12" class="mt-1" v-if="useDeposit">
            <label>Määrä</label>
            <v-text-field
              v-model.number="deposit"
              type="number"
              :rules="validations.required"
              step="0.01"
              suffix="€"
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col cols="12" v-if="useDeposit">
            <v-checkbox
              v-model="socialWelfareAccepted"
              label="Hyväksytään Kelan tai sosiaalitoimen maksusitoumus"
              hide-details
              style="margin-top: 0"
            ></v-checkbox>
          </v-col>

          <v-col cols="12" v-if="useDeposit">
            <v-radio-group
              v-model="depositType"
              :mandatory="true"
              @change="getIban"
              hide-details
              style="margin-top: 0"
            >
              <v-radio label="Lasku" value="invoice"></v-radio>
              <v-radio label="Digivakuus" value="digital"></v-radio>
              <v-radio label="Pankkitakaus" value="bank"></v-radio>
              <v-radio label="Maksu vuokranantajan tilille" value="payment"></v-radio>
              <v-radio label="Vakuuden siirto" value="transfer"></v-radio>
              <v-radio label="Maksupääte" value="paymentTerminal"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col v-if="useDeposit && depositType == 'payment'" cols="12">
            <label>Maksetaan tilille</label>
            <v-text-field
              v-model="depositIban"
              :rules="validations.required"
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col v-if="useDeposit" cols="12">
            <label>Vakuuden eräpäivä</label>
            <v-menu
              ref="dismissalMenu"
              v-model="depositMenu"
              :close-on-content-click="false"
              min-width="290"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormattedDeposit"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                  append-icon="mdi-calendar"
                  v-prevent-manual-input
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="depositDueDate"
                first-day-of-week="1"
                @input="depositMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-checkbox
            v-model="absoluteGuaranteeIsUsed"
            label="Omavelkainen takaus"
            style="margin-top: 5px"
            hide-details
          ></v-checkbox>

          <div v-if="absoluteGuaranteeIsUsed" class="mt-1">
            <v-col cols="12">
              <label>Takaajan nimi</label>
              <v-text-field
                v-model="absoluteGuaranteeName"
                outlined
                :rules="validations.required"
                dense
                hide-details
              />
            </v-col>

            <v-col cols="12">
              <label>Takaajan sotu</label>
              <v-text-field
                v-model.trim="absoluteGuaranteeSocial"
                outlined
                :rules="validations.required"
                dense
                hide-details
              />
            </v-col>

            <v-col cols="12">
              <label>Max korvattava summa €</label>
              <v-text-field
                v-model.number="absoluteGuaranteeMaxAmount"
                type="number"
                step="0.01"
                outlined
                dense
                hide-details
              />
            </v-col>
          </div>
        </v-col>

        <!-- RENT INCREASE-->
        <v-col cols="12" md="4">
          <h2>Vuokran vuosittainen korotus</h2>

          <v-checkbox
            v-model="noRentIncrease"
            hide-details
            label="Vuokraa ei koroteta"
          ></v-checkbox>

          <div v-if="!noRentIncrease" class="mt-2">
            <v-col cols="12">
              <label>Vuokran korotuksen ajankohta</label>
              <v-text-field
                v-model="rentIncreaseTime"
                placeholder="Sanallinen kuvaus..."
                outlined
                :rules="validations.required"
                dense
                persistent-hint
                hint="Näytetään vuokrasopimuksella"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <label>Seuraava vuokran korotuksen pvm.</label>
              <v-menu
                ref="rentIncreaseMenu"
                v-model="rentIncreaseMenu"
                :close-on-content-click="false"
                min-width="290"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormattedRentIncrease"
                    v-bind="attrs"
                    v-on="on"
                    :rules="validations.required"
                    outlined
                    dense
                    append-icon="mdi-calendar"
                    v-prevent-manual-input
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="nextRentIncreaseDate"
                  first-day-of-week="1"
                  @input="rentIncreaseMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12">
              <label style="margin-top: 25px">Korotuksen peruste</label>
              <v-select
                v-model="rentIncreaseType"
                :items="globalValues.rentIncreaseValues"
                dense
                outlined
                item-text="text"
                item-value="val"
                :rules="validations.required"
                hide-details
              ></v-select>
            </v-col>

            <div
              v-if="
                rentIncreaseType === 'index' ||
                rentIncreaseType === 'indexPlusPercentage' ||
                rentIncreaseType === 'consumerIndex' ||
                rentIncreaseType === 'consumerIndexPlusPercentage' ||
                rentIncreaseType === 'propertyMaintenanceIndex' ||
                rentIncreaseType === 'propertyMaintenancePlusPercentageIndex'
              "
            >
              <v-col
                v-if="
                  rentIncreaseType == 'propertyMaintenanceIndex' ||
                  rentIncreaseType == 'propertyMaintenancePlusPercentageIndex'
                "
                cols="12"
              >
                <v-checkbox
                  v-model="compareToPreviousIndex"
                  outlined
                  dense
                  hide-details
                  label="Vertaile edelliseen indeksin pistelukuun (oletuksena alkuperäiseen)"
                ></v-checkbox>
              </v-col>

              <v-col cols="12" dense class="mt-1 mb-1">
                <v-btn color="primary" @click="getLatestIndex">Hae viimeisin pisteluku</v-btn>
              </v-col>

              <v-col cols="12">
                <label>Indeksin kk/vuosi</label>
                <v-menu
                  ref="indexMenu"
                  v-model="indexMenu"
                  :close-on-content-click="false"
                  min-width="290"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormattedIndexStartDate"
                      v-bind="attrs"
                      v-on="on"
                      :rules="validations.required"
                      outlined
                      dense
                      append-icon="mdi-calendar"
                      v-prevent-manual-input
                      hide-details
                      :loading="loadingIndex"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="indexStartDate"
                    first-day-of-week="1"
                    type="month"
                    @input="indexMenu = false"
                    @change="getIndex"
                    :allowed-dates="allowedIndexDates"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12">
                <label>Indeksipisteluku</label>
                <v-text-field
                  v-model.number="indexStartValue"
                  type="number"
                  step="0.01"
                  outlined
                  :rules="validations.required"
                  dense
                  hide-details
                  :loading="loadingIndex"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <label>Alkuperäinen vuokra</label>
                <v-text-field
                  v-model.number="startRentPrice"
                  outlined
                  dense
                  type="number"
                  step="1"
                  suffix="€"
                  hide-details
                  :rules="validations.required"
                >
                </v-text-field>
              </v-col>
            </div>

            <div
              v-if="
                rentIncreaseType == 'percentage' ||
                rentIncreaseType == 'indexPlusPercentage' ||
                rentIncreaseType == 'consumerIndexPlusPercentage' ||
                rentIncreaseType == 'propertyMaintenancePlusPercentageIndex'
              "
            >
              <v-col cols="12">
                <label>Prosenttia</label>
                <v-text-field
                  v-model.number="rentIncreasePercentage"
                  type="number"
                  step="0.01"
                  outlined
                  :rules="validations.required"
                  dense
                  hide-details
                  suffix="%"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <label>Prosenttikorotuksen tarkennus</label>
                <v-select
                  v-model="percentagePrefix"
                  :items="['enintään', 'vähintään']"
                  outlined
                  dense
                  clearable
                  hide-details
                ></v-select>
              </v-col>
            </div>

            <v-col v-if="rentIncreaseType == 'fixed'" cols="12">
              <label>Euroa (€)</label>
              <v-text-field
                v-model.number="rentIncreaseFixed"
                type="number"
                step="0.01"
                outlined
                :rules="validations.required"
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col v-if="rentIncreaseType == 'own'" cols="12">
              <label>Tarkenne</label>
              <v-text-field
                v-model="rentIncreaseOwn"
                outlined
                :rules="validations.required"
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <label>Kommentit</label>
              <v-text-field
                v-model="rentIncreaseComment"
                outlined
                dense
                persistent-hint
                hint="Näytetään vuokrankorotusta tehdessä"
              ></v-text-field>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { eventBus } from "../../main";
import mixins from "../../mixins/mixins";
import { mapActions, mapMutations, mapState } from "vuex";
import globalValues from "@/configs/globalValues";
import validations from "@/validations";
import moment from "moment";

export default {
  mixins: [mixins],

  data() {
    return {
      rentIncreaseMenu: false,
      startMenu: false,
      endMenu: false,
      dismissalMenu: false,
      moveInMenu: false,
      firstRentMenu: false,
      depositMenu: false,
      indexMenu: false,
      dateFormattedRentIncrease: "",
      dateFormattedStart: "",
      dateFormattedEnd: "",
      dateFormattedMoveIn: "",
      dateFormattedRentFirst: "",
      dateFormattedDismissal: "",
      dateFormattedDeposit: "",
      dateFormattedIndexStartDate: "",
      globalValues,
      validations,
    };
  },

  created() {
    this.$root.$refs.lease = this;

    if (this.$store.state.rentalContract.lease.startDate)
      this.dateFormattedStart = this.formatDate(this.$store.state.rentalContract.lease.startDate);
    if (this.$store.state.rentalContract.lease.endDate)
      this.dateFormattedEnd = this.formatDate(this.$store.state.rentalContract.lease.endDate);
    if (this.$store.state.rentalContract.lease.dismissalDate)
      this.dateFormattedDismissal = this.formatDate(
        this.$store.state.rentalContract.lease.dismissalDate
      );
    if (this.$store.state.rentalContract.lease.moveInDate)
      this.dateFormattedMoveIn = this.formatDate(this.$store.state.rentalContract.lease.moveInDate);
    if (this.$store.state.rentalContract.lease.firstRentDate)
      this.dateFormattedRentFirst = this.formatDate(
        this.$store.state.rentalContract.lease.firstRentDate
      );
    if (this.$store.state.rentalContract.lease.depositDueDate)
      this.dateFormattedDeposit = this.formatDate(
        this.$store.state.rentalContract.lease.depositDueDate
      );
    if (this.$store.state.rentalContract.lease.nextRentIncreaseDate)
      this.dateFormattedRentIncrease = this.formatDate(
        this.$store.state.rentalContract.lease.nextRentIncreaseDate
      );
    if (this.$store.state.rentalContract.lease.indexStartDate)
      this.dateFormattedIndexStartDate = this.formatDateMonthName(
        this.$store.state.rentalContract.lease.indexStartDate
      );

    // set rent terms and duedate text
    if (this.$route.path.includes("create")) {
      // set default duedate text
      if (this.rentDueDate == "")
        this.rentDueDate = this.currentUser.currentAccount.settings.defaultDueDateText;

      // set default moveout text
      if (this.moveOut == "") this.moveOut = this.currentUser.currentAccount.settings.rentTerms;

      // set default duedate number
      const defaultDueDateNumber = this.currentUser.currentAccount.settings.defaultDueDateNumber;
      if (defaultDueDateNumber && !this.rentDueDateNumber) {
        this.rentDueDateNumber = defaultDueDateNumber;
      }
    }
  },

  computed: {
    ...mapState("product", ["products"]),
    ...mapState("account", ["currentUser"]),
    ...mapState("rentIncrease", ["indexValue", "indexDate", "loadingIndex"]),

    leaseType: {
      get() {
        return this.$store.state.rentalContract.lease.leaseType;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            leaseType: value,
          },
        });
      },
    },
    startDate: {
      get() {
        return this.$store.state.rentalContract.lease.startDate;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            startDate: value,
          },
        });
      },
    },
    endDate: {
      get() {
        return this.$store.state.rentalContract.lease.endDate;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            endDate: value,
          },
        });
      },
    },
    showDismissalDate: {
      get() {
        return this.$store.state.rentalContract.lease.showDismissalDate;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            showDismissalDate: value,
          },
        });
      },
    },
    dismissalDate: {
      get() {
        return this.$store.state.rentalContract.lease.dismissalDate;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            dismissalDate: value,
          },
        });
      },
    },
    moveOut: {
      get() {
        return this.$store.state.rentalContract.lease.moveOut;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            moveOut: value,
          },
        });
      },
    },
    moveInDate: {
      get() {
        return this.$store.state.rentalContract.lease.moveInDate;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            moveInDate: value,
          },
        });
      },
    },
    firstRentDate: {
      get() {
        return this.$store.state.rentalContract.lease.firstRentDate;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            firstRentDate: value,
          },
        });
      },
    },
    rent: {
      get() {
        return this.$store.state.rentalContract.lease.rent;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            rent: value,
          },
        });
      },
    },
    rentProduct: {
      get() {
        return this.$store.state.rentalContract.lease.rentProduct;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            rentProduct: value,
          },
        });
      },
    },
    waterProduct: {
      get() {
        return this.$store.state.rentalContract.lease.waterProduct;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            waterProduct: value,
          },
        });
      },
    },
    otherProducts: {
      get() {
        return this.$store.state.rentalContract.lease.otherProducts;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            otherProducts: value,
          },
        });
      },
    },
    rentDueDate: {
      get() {
        return this.$store.state.rentalContract.lease.rentDueDate;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            rentDueDate: value,
          },
        });
      },
    },
    rentDueDateNumber: {
      get() {
        return this.$store.state.rentalContract.lease.rentDueDateNumber;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            rentDueDateNumber: value,
          },
        });
      },
    },
    waterChargeType: {
      get() {
        return this.$store.state.rentalContract.lease.waterChargeType;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            waterChargeType: value,
          },
        });
      },
    },
    waterChargePerMonth: {
      get() {
        return this.$store.state.rentalContract.lease.waterChargePerMonth;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            waterChargePerMonth: value,
          },
        });
      },
    },
    waterChargeCount: {
      get() {
        return this.$store.state.rentalContract.lease.waterChargeCount;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            waterChargeCount: value,
          },
        });
      },
    },
    electricity: {
      get() {
        return this.$store.state.rentalContract.lease.electricity;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            electricity: value,
          },
        });
      },
    },
    electricityAmount: {
      get() {
        return this.$store.state.rentalContract.lease.electricityAmount;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            electricityAmount: value,
          },
        });
      },
    },
    otherCostsDetails: {
      get() {
        return this.$store.state.rentalContract.lease.otherCostsDetails;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            otherCostsDetails: value,
          },
        });
      },
    },
    otherCosts: {
      get() {
        return this.$store.state.rentalContract.lease.otherCosts;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            otherCosts: value,
          },
        });
      },
    },
    saunaCost: {
      get() {
        return this.$store.state.rentalContract.lease.saunaCost;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            saunaCost: value,
          },
        });
      },
    },
    parkingCost: {
      get() {
        return this.$store.state.rentalContract.lease.parkingCost;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            parkingCost: value,
          },
        });
      },
    },
    laundretteCost: {
      get() {
        return this.$store.state.rentalContract.lease.laundretteCost;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            laundretteCost: value,
          },
        });
      },
    },
    useDeposit: {
      get() {
        return this.$store.state.rentalContract.lease.useDeposit;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            useDeposit: value,
          },
        });
      },
    },
    deposit: {
      get() {
        return this.$store.state.rentalContract.lease.deposit;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            deposit: value,
          },
        });
      },
    },
    socialWelfareAccepted: {
      get() {
        return this.$store.state.rentalContract.lease.socialWelfareAccepted;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            socialWelfareAccepted: value,
          },
        });
      },
    },
    absoluteGuaranteeIsUsed: {
      get() {
        return this.$store.state.rentalContract.lease.absoluteGuarantee.isUsed;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            absoluteGuarantee: {
              isUsed: value,
            },
          },
        });
      },
    },
    absoluteGuaranteeName: {
      get() {
        return this.$store.state.rentalContract.lease.absoluteGuarantee.name;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            absoluteGuarantee: {
              isUsed: this.$store.state.rentalContract.lease.absoluteGuarantee.isUsed,
              name: value,
              social: this.$store.state.rentalContract.lease.absoluteGuarantee.social,
              maxAmount: this.$store.state.rentalContract.lease.absoluteGuarantee.maxAmount,
            },
          },
        });
      },
    },
    absoluteGuaranteeSocial: {
      get() {
        return this.$store.state.rentalContract.lease.absoluteGuarantee.social;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            absoluteGuarantee: {
              isUsed: this.$store.state.rentalContract.lease.absoluteGuarantee.isUsed,
              name: this.$store.state.rentalContract.lease.absoluteGuarantee.name,
              social: value.toUpperCase(),
              maxAmount: this.$store.state.rentalContract.lease.absoluteGuarantee.maxAmount,
            },
          },
        });
      },
    },
    absoluteGuaranteeMaxAmount: {
      get() {
        return this.$store.state.rentalContract.lease.absoluteGuarantee.maxAmount;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            absoluteGuarantee: {
              isUsed: this.$store.state.rentalContract.lease.absoluteGuarantee.isUsed,
              name: this.$store.state.rentalContract.lease.absoluteGuarantee.name,
              social: this.$store.state.rentalContract.lease.absoluteGuarantee.social,
              maxAmount: value,
            },
          },
        });
      },
    },
    depositType: {
      get() {
        return this.$store.state.rentalContract.lease.depositType;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            depositType: value,
          },
        });
      },
    },
    depositIban: {
      get() {
        return this.$store.state.rentalContract.lease.depositIban;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            depositIban: value,
          },
        });
      },
    },
    depositDueDate: {
      get() {
        return this.$store.state.rentalContract.lease.depositDueDate;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            depositDueDate: value,
          },
        });
      },
    },
    noRentIncrease: {
      get() {
        return this.$store.state.rentalContract.lease.noRentIncrease;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            noRentIncrease: value,
          },
        });
      },
    },
    nextRentIncreaseDate: {
      get() {
        return this.$store.state.rentalContract.lease.nextRentIncreaseDate;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            nextRentIncreaseDate: value,
          },
        });
      },
    },
    rentIncreaseType: {
      get() {
        return this.$store.state.rentalContract.lease.rentIncreaseType;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            rentIncreaseType: value,
          },
        });
      },
    },
    rentIncreaseTime: {
      get() {
        return this.$store.state.rentalContract.lease.rentIncreaseTime;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            rentIncreaseTime: value,
          },
        });
      },
    },
    startRentPrice: {
      get() {
        return this.$store.state.rentalContract.lease.startRentPrice;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            startRentPrice: value,
          },
        });
      },
    },
    indexStartValue: {
      get() {
        return this.$store.state.rentalContract.lease.indexStartValue;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            indexStartValue: value,
          },
        });
      },
    },
    indexStartDate: {
      get() {
        return this.$store.state.rentalContract.lease.indexStartDate;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            indexStartDate: value,
          },
        });
      },
    },
    rentIncreasePercentage: {
      get() {
        return this.$store.state.rentalContract.lease.rentIncreasePercentage;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            rentIncreasePercentage: value,
          },
        });
      },
    },
    percentagePrefix: {
      get() {
        return this.$store.state.rentalContract.lease.percentagePrefix;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            percentagePrefix: value,
          },
        });
      },
    },
    rentIncreaseFixed: {
      get() {
        return this.$store.state.rentalContract.lease.rentIncreaseFixed;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            rentIncreaseFixed: value,
          },
        });
      },
    },

    rentIncreaseOwn: {
      get() {
        return this.$store.state.rentalContract.lease.rentIncreaseOwn;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            rentIncreaseOwn: value,
          },
        });
      },
    },
    rentIncreaseComment: {
      get() {
        return this.$store.state.rentalContract.lease.rentIncreaseComment;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            rentIncreaseComment: value,
          },
        });
      },
    },
    compareToPreviousIndex: {
      get() {
        return this.$store.state.rentalContract.lease.compareToPreviousIndex;
      },
      set(value) {
        this.$store.commit("setContractField", {
          lease: {
            ...this.$store.state.rentalContract.lease,
            compareToPreviousIndex: value,
          },
        });
      },
    },
  },

  watch: {
    startDate() {
      this.dateFormattedStart = this.formatDate(this.startDate);
    },
    endDate() {
      this.dateFormattedEnd = this.formatDate(this.endDate);
    },
    dismissalDate() {
      this.dateFormattedDismissal = this.formatDate(this.dismissalDate);
    },
    moveInDate() {
      this.dateFormattedMoveIn = this.formatDate(this.moveInDate);
    },
    firstRentDate() {
      this.dateFormattedRentFirst = this.formatDate(this.firstRentDate);
    },
    depositDueDate() {
      this.dateFormattedDeposit = this.formatDate(this.depositDueDate);
    },
    nextRentIncreaseDate() {
      this.dateFormattedRentIncrease = this.formatDate(this.nextRentIncreaseDate);
    },
    rentIncreaseType(val) {
      if (
        val &&
        val != "propertyMaintenanceIndex" &&
        val != "propertyMaintenancePlusPercentageIndex"
      ) {
        this.compareToPreviousIndex = false;
      }
    },
    indexStartDate() {
      this.dateFormattedIndexStartDate = this.formatDateMonthName(this.indexStartDate);
    },
    indexValue(val) {
      this.indexStartValue = val;
    },
    indexDate(val) {
      this.indexStartDate = val;
    },
    otherProducts: {
      deep: true,
      handler() {
        this.$store.commit("updateLocalStorage");
      },
    },
  },

  methods: {
    ...mapMutations("rentIncrease", ["setLoadingIndex"]),
    ...mapActions("rentIncrease", ["getIndexValue", "getLatestIndexValue"]),

    getIndex(date) {
      if (date) {
        this.setLoadingIndex(true);
        this.getIndexValue({ date, type: this.rentIncreaseType });
      }
    },

    getLatestIndex() {
      this.indexStartDate = "";
      this.indexStartValue = null;

      this.setLoadingIndex(true);
      this.getLatestIndexValue(this.rentIncreaseType);
    },

    checkIfValid() {
      eventBus.$emit("checklease", this.$refs.form.validate());
    },

    getIban(type) {
      if (type == "payment") {
        this.depositIban = this.currentUser.currentAccount.iban;
      }
    },

    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split(".");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    updateOtherProducts() {
      this.$store.commit("updateLocalStorage");
    },

    addOtherProduct() {
      this.otherProducts.push({ productId: null, amount: null, count: null });
    },

    allowedIndexDates(val) {
      const indexType = this.rentIncreaseType;
      const dateWithoutYear = moment(val).format("MM");

      const allowedQMonths = ["01", "04", "07", "10"];

      if (
        indexType === "propertyMaintenanceIndex" ||
        indexType === "propertyMaintenancePlusPercentageIndex"
      ) {
        if (allowedQMonths.includes(dateWithoutYear)) return true;
        else return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped></style>
