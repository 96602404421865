import apartmentService from "../../services/apartment.service";
import _ from "lodash";
import { showPopup } from "../../utils/helpers";
import { defaultApartment, defaultApartmentLoanForm } from "../../configs/defaultState";

const state = {
  currentApartment: _.cloneDeep(defaultApartment),
  apartmentCopy: null,
  apartments: [],
  activeApartments: [],
  selectedApartments: [],
  currentLoan: {},
  loanIndex: null,
  totalLength: 0,
  errorFiles: [],
  images: [],
  uploading: false,
  uploadProgress: 0,
  loading: false,
};

const mutations = {
  setLoading(state, val) {
    state.loading = val;
  },

  addAllApartments(state, data) {
    state.apartments = data.apartments;
    state.totalLength = data.totalLength;
  },

  setApartmentCopy(state, apartment) {
    state.apartmentCopy = _.cloneDeep(apartment);
  },

  setDefaultApartment(state) {
    state.currentApartment = _.cloneDeep(defaultApartment);
  },

  setField(state, obj) {
    state.currentApartment = _.set(state.currentApartment, obj.field, obj.val);
  },

  addActiveApartments(state, apartments) {
    state.activeApartments = [...apartments, ...state.activeApartments].filter(
      (value, index, self) => index === self.findIndex((t) => String(t._id) == String(value._id))
    );
  },

  setApartment(state, apartment) {
    if (apartment.currentLoans) {
      apartment.currentLoans.forEach((el) => {
        el.startDate = new Date(el.startDate).toISOString().substring(0, 10);
      });
    } else {
      apartment.currentLoans = [];
    }
    state.currentApartment = { ...apartment };
  },

  deleteApartment(state, id) {
    state.apartments = state.apartments.filter((el) => {
      return el._id != id;
    });
  },

  setSelectedApartments(state, apartments) {
    state.selectedApartments = [...apartments];
  },

  replaceSelectedApartments(state, apartments) {
    apartments.forEach((el) => {
      state.apartments.splice(
        state.apartments.findIndex((x) => x._id === el._id),
        1,
        el
      );
    });
  },

  // For both loanpayments and charges
  addOldCharge(state, obj) {
    state.currentApartment[obj.type].push(obj.charge);
  },

  setCurrentLoan(state, data) {
    state.currentLoan = { ...data.loan };
    state.loanIndex = data.index;
  },

  setDefaultLoanForm(state) {
    state.currentLoan = _.cloneDeep(defaultApartmentLoanForm);
  },

  addLoanPayment(state) {
    state.currentApartment.currentLoans.push({ ...state.currentLoan });
  },

  editLoanPayment(state, removeCurrentAmount = false) {
    if (removeCurrentAmount) {
      state.currentLoan.currentAmount = null;
      delete state.currentLoan.currentK;
      delete state.currentLoan.currentKDate;
      delete state.currentLoan.capitalPaidDate;
      delete state.currentLoan.skipDate;
    }
    state.currentApartment.currentLoans.splice(state.loanIndex, 1, { ...state.currentLoan });
  },

  deleteLoanPayment(state, index) {
    state.currentApartment.currentLoans.splice(index, 1);
  },

  addOwner(state) {
    state.currentApartment.owners.push({
      name: "",
      holding: null,
      ownerId: null,
      onModel: "",
    });
  },

  deleteOwner(state, index) {
    state.currentApartment.owners.splice(index, 1);
  },

  addAssignmentRealtor(state) {
    state.currentApartment.assignment.realtors.push({
      name: "",
      rewardPercent: null,
      realtorId: null,
    });
  },

  deleteAssignmentRealtor(state, index) {
    state.currentApartment.assignment.realtors.splice(index, 1);
  },

  addRoom(state) {
    state.currentApartment.rooms.push({
      name: "",
      active: true,
    });
  },

  deleteRoom(state, index) {
    const room = state.currentApartment.rooms[index];
    if (room._id) state.currentApartment.rooms[index].active = false;
    else state.currentApartment.rooms.splice(index, 1);
  },
};

const actions = {
  async getAllApartments({ commit }, data) {
    try {
      const res = await apartmentService.getAllApartments(data);
      commit("addAllApartments", {
        apartments: res.data.apartments,
        totalLength: res.data.totalLength,
      });
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async getOneApartment({ commit }, apartmentId) {
    try {
      const res = await apartmentService.getOneApartment(apartmentId);
      // Set data
      commit("setApartment", res.data.apartment);
      // Set images
      commit("image/setImages", res?.data?.apartment?.images || [], { root: true });
      // set loading false
      commit("setLoading", false);
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async updateApartment({ commit }, data) {
    try {
      const res = await apartmentService.updateApartment(data);
      // Set data
      commit("setApartment", res.data.apartment);
      showPopup("Vuokrakohde päivitetty", "success");
    } catch (err) {
      throw new Error(err);
    }
  },

  async updateMultipleApartments({ commit }, obj) {
    try {
      const { data } = await apartmentService.updateMultipleApartments(obj);
      commit("replaceSelectedApartments", data.apartments);
      showPopup("Vuokrakohteet päivitetty", "success");
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async deleteApartment({ commit }, id) {
    try {
      await apartmentService.deleteApartment(id);
      commit("deleteApartment", id);
      showPopup("Vuokrakohde poistettu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async searchFreeApartments({ commit }, data) {
    try {
      let res = await apartmentService.searchFreeApartments(data.url, data.query);

      commit("addAllApartments", {
        apartments: res.data.apartments,
        totalLength: res.data.totalLength,
      });
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async searchApartment({ commit }, url) {
    try {
      const res = await apartmentService.searchApartment(url);

      commit("addActiveApartments", res.data.apartments);
    } catch (err) {
      showPopup(err, " error");
    }
  },

  getApartmentsById({ commit }, ids) {
    return apartmentService
      .getApartmentsById(ids)
      .then((res) => {
        commit("addActiveApartments", res.data.apartments);
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  async createApartment(ctx, data) {
    try {
      await apartmentService.createApartment(data);
      showPopup("Vuokrakohde luotu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async updateApartmentPublicSettings(ctx, data) {
    try {
      await apartmentService.updateApartmentPublicSettings(data);
      showPopup("Asunnon tiedot päivitetty", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },
};

// Exports
export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
